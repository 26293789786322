<template>
    <form autocomplete="true" class="address-inputs__wrapper">
        <div class="btn__wrapper">
            <CRButton
                class="btn"
                v-for="city in cities"
                :key="city.id"
                :active="addressData.city?.id === city.id"
                @click.prevent="addressData.city = city"
                pattern="section"
            >
                {{ city.name }}
            </CRButton>
        </div>
        <div v-for="input in renderAddressInputsData(isEditMode)" :key="input.id" :class="input.class">
            <Component
                v-if="v$.addressData[input.value]"
                v-bind:is="input.component"
                v-model="v$.addressData[input.value].$model"
                :type="input.type"
                :label="input.label"
                :name="input.value"
                :placeholder="input.placeholder"
                :required="input.isRequired"
                :errors="v$.addressData[input.value]?.$errors"
                is-vuelidate
                :max-length="input?.maxLength"
                @onChange="$filters.clearError(addressErrors, input.value)"
                :autocomplete="input.autocomplete"
            >
                <template v-if="input.value === 'postalCode'" #helpSlot>
                    <a class="postal-link" href="https://www.post.lt/pasto-kodu-ir-adresu-paieska" target="_blank">
                        {{ $t('common.lookUpPostalCode') }}
                    </a>
                </template>
            </Component>
        </div>
        <RequiredText class="w-full" />
        <slot name="footer" :address="addressData" />
    </form>
</template>

<script>
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import { renderAddressInputsData } from '@/config/checkOut/address';
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import { required, helpers, minLength, maxLength, requiredIf } from '@vuelidate/validators';

    export const INITIAL_ADDRESS_DATA = {
        name: '',
        // city: null,
        street: '',
        houseNumber: '',
        postalCode: '',
        apartmentNumber: '',
        floor: '',
        extraInformation: '',
    };

    export default {
        name: 'AddressForm',
        components: { CRInput, CRTextarea, CRButton, RequiredText },
        props: {
            isEditMode: {
                type: Boolean,
                default: false,
            },
            address: {
                type: Object,
                default: null,
            },
            addressErrors: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['onFormChange'],
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                renderAddressInputsData,

                addressData: { 
                    ...INITIAL_ADDRESS_DATA, 
                    city: this.selectedCity 
                },
            };
        },
        validations() {
            return {
                addressData: {
                    name: {
                        requiredIf: helpers.withMessage(this.$t('errors.required'), requiredIf(this.isEditMode)),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(2)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    street: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(2)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    postalCode: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    apartmentNumber: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    floor: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    houseNumber: {
                        required: helpers.withMessage(this.$t('errors.required'), required),
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(1)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(100)
                        ),
                    },
                    extraInformation: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(2)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(500)
                        ),
                    },
                },
            };
        },
        computed: {
            ...mapGetters('city', ['cities', 'selectedCity']),
        },
        watch: {
            address() {
                this.addressData = { ...this.address };
            },
            addressData: {
                handler(value) {
                    this.$emit('onFormChange', value);
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {
            if (this.isEditMode) {
                this.addressData = { ...this.address };
            }
        },
    };
</script>

<style lang="scss" scoped>
    .address-inputs__wrapper {
        @include row-align-start-justify-between;
        flex-wrap: wrap;

        .postal-link {
            display: inline-block;
            color: $primary;
            font-size: $font-14;
            font-weight: 700;
            text-decoration: underline;
            margin-top: 8px;
        }

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 18px;

            .btn {
                cursor: pointer;
                margin-bottom: 10px;

                margin-right: 8px;
            }

            .btn:last-child {
                margin-right: 0;
            }
        }

        .input {
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .textarea {
            width: 100%;
            height: 115px;
        }

        .description {
            margin-top: 16px;

            .btn {
                display: inline;
            }
        }

        @include media($sm) {
            .input {
                width: 49%;
                margin-bottom: 20px;
            }

            .street {
                width: 100%;
            }
        }
    }
</style>
